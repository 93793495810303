import React, { Component } from 'react';
import '../css/main.css';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from './Tooltip';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import MomentUtils from "@date-io/moment"
import "date-fns";


export class BudgetRecommendationParameters extends Component {
  
   constructor(props) {
        super(props);
        this.handleDateChangeStart = this.handleDateChangeStart.bind(this);
        this.handleDateChangeEnd = this.handleDateChangeEnd.bind(this);
        this.state = {
            selectedDateStart: null,
            selectedDateEnd: null,
            targetValue: '',
            target: "Budget"
        };
    };

    componentDidUpdate = () =>
    {
        if (this.props.resetBudgetParameters)
        {
            this.setState({
                selectedDateStart: null,
                selectedDateEnd: null,
                targetValue: '',
                target: "Budget"
            });

            this.props.didBudgetParamatersReset()
        }
    }

    handleDateChangeStart(date) {
        this.props.handleDateChangeStart(date, this.state.selectedDateEnd, this.state.targetValue)
        this.setState({
            selectedDateStart: date
        })
      }

    handleDateChangeEnd(date) {
        this.props.handleDateChangeEnd(this.state.selectedDateStart, date, this.state.targetValue)
        this.setState({
            selectedDateEnd: date
        })    
      }

    targetValueChange = (e) =>
    {
       this.props.targetValueChange(this.state.selectedDateStart, this.state.selectedDateEnd, this.state.targetValue)
    }

    targetDropDownChange = (e) =>
    {
        var targetDropDown = e.target.value.replace(/\s/g, '_').replace('/', 'per').replace("_(e.g._ROAS)", "").replace("_(e.g._CPA)", "")
        this.props.targetDropDownChange(this.state.selectedDateStart, this.state.selectedDateEnd, this.state.targetValue, targetDropDown)
        this.setState({
            target: targetDropDown
        })
           
    }

    resetRecommendationValues = () =>
    {
       this.props.resetRecommendationValues()
       this.setState({isBudgetRecommendation:false, resetBudget:true, selectedDateStart: null, selectedDateEnd: null, targetValue: ''})
    }

    render() {
        let targetTooltip = ["Specify a budget, KPI, or efficiency target. Budget target example:" ,<br/>, "setting a budget target of 1,000,000 (dollars) will output" ,<br/>, "the most efficient way to allocate the investment (meeting your constraints) over the specified time period." ,<br/>, "KPI target example: setting a KPI target of 5,000 (e.g. leads), will output the minimum budget required to meet that goal, optimally allocated across your initiatives." ,<br/>, "KPI / Spend and Spend / KPI example: specify this if you have no maximum budget, but have an efficiency target, such as an ROAS of 2." ,<br/>," The output will display the maximum investment that can be made while not exceeding this efficiency goal. Note, KPI / Spend would be applicable to a metric such as ROAS, while Spend / KPI would be applicable to a metric such as cost per lead."];
        let dateRangeTooltip = "Specify the time period (start and end dates) the budget plan should generate budget allocation recommendations and forecasts for. For example, to understand how to best allocate one million dollars for your initiatives next month, enter 1,000,000 into the Target field with Budget selected, then select the first day of next month (start) and last day of next month (end) in the Date Range module.";
        return(
            <React.Fragment>
               <div style={{paddingLeft:"70px"}} className="row budget-recommendation-row">
                    <div style={{maxWidth:"400px"}} className="col-sm-4 budget-recommendation-container-target">
                        <div><span className='budget-recommendation-text'>Target</span> &nbsp; <Tooltip content={targetTooltip} direction="right"><InfoIcon></InfoIcon></Tooltip> </div>
                        <div style={{display:"inline-block"}}>
                            <select onChange={this.targetDropDownChange} className="target-dropdown">
                                <option>Budget</option>
                                <option>KPI</option>
                                <option>KPI / Spend (e.g. ROAS)</option> 
                                <option>Spend / KPI (e.g. CPA)</option>
                            </select>
                            <input className='target-text-input' onBlur={this.targetValueChange} value={this.state.targetValue}  onChange={e => this.setState({ targetValue: e.target.value })}  placeholder='e.g. 100000' type="number" ></input>
                        </div>
                        <div>
                            <button className='reset-btn' onClick={this.resetRecommendationValues}>RESET</button>
                        </div>
                    </div>
                    <div style={{maxWidth:"350px"}} className="col-sm-4 budget-recommendation-container-date">
                        <div><span className='budget-recommendation-text'>Date Range</span>  &nbsp; <Tooltip content={dateRangeTooltip} direction="right"><InfoIcon></InfoIcon></Tooltip> </div> 
                        <div className="date-picker-div">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <span className='date-picker-text'><strong>Start</strong></span><DatePicker className='date-picker' clearable animateYearScrolling={false} placeholder="MM/DD/YYYY" format={"MM/DD/YYYY"}
                             onChange={this.handleDateChangeStart} autoOk={true} value={this.state.selectedDateStart}></DatePicker>
                             <span className='date-picker-text'><strong>End</strong></span><DatePicker className='date-picker' clearable animateYearScrolling={false} placeholder="MM/DD/YYYY" format={"MM/DD/YYYY"}
                             onChange={this.handleDateChangeEnd} autoOk={true} value={this.state.selectedDateEnd}></DatePicker>
                        </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    {this.props.lowerBound ?
                         <div className="col-lg-4 budget-recommendation-container-ranges custom-col">
                         <div><span className='budget-recommendation-text'>Monthly Input Ranges</span></div> 
                          <div style={{marginTop:0}} class="row">
                          <table style={{ width: "100%", textAlign: "center", marginTop: "10px" }}>
                             <thead>
                                 <tr>
                                 <th></th>
                                 <th>Lower Bound</th>
                                 <th>Midpoint</th>
                                 <th>Upper Bound</th>
                                 </tr>
                             </thead>
                             <tbody>
                                 <tr>
                                 <td><b>Budget</b></td>
                                 <td>{this.props.lowerBound ? `$${this.props.lowerBound.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "" }</td>
                                 <td>{this.props.lowerBound && this.props.upperBound ? `$${(((this.props.lowerBound + this.props.upperBound) / 2)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : ""}</td>
                                 <td>{this.props.upperBound ? `$${this.props.upperBound.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : ""}</td>
                                 </tr>
                                 <tr>
                                 <td><b>{this.props.conversionType()}</b></td>
                                 <td>{this.props.lowerEfficiency?.toFixed(2)}</td>
                                 <td>{this.props.lowerEfficiency && this.props.upperEfficiency ? ((this.props.lowerEfficiency + this.props.upperEfficiency) / 2)?.toFixed(2) : ""}</td>
                                 <td>{this.props.upperEfficiency?.toFixed(2)}</td>
                                 </tr>
                             </tbody>
                             </table>
                          </div>
                     </div>
                        : ""
                    }
                   
                </div>
            </React.Fragment>
         );
    }
}

export default BudgetRecommendationParameters




