import React, { Component } from 'react';
import CampaignGroupingTemplate from './BudgetResultsOutputTemplates/CampaignGroupingTemplate';
import CampaignTemplate from './BudgetResultsOutputTemplates/CampaignTemplate';
import ReactGA from "react-ga4";
import { Redirect } from "react-router-dom";

ReactGA.initialize("G-YQ7D35VK7L")

export class BudgetResultsOutput extends Component {
  static displayName = BudgetResultsOutput.name;

  constructor(props) {
        super(props);

        this.state =
        {
            selectedBudgetOutput: this.props.location.state.selectedBudgetOutput,
            accountId: this.props.location.state.accountId,
            selectedBudgetOutputId: this.props.location.state.selectedBudgetOutputId,
            conversionType: this.props.location.state.conversionType,
            modelFrequency: this.props.location.state.modelFrequency,
            campaignGroupingsAmount: this.props.location.state.campaignGroupingsAmount,
            isRedirect: false,
            lowerBound: this.props.location.state.lowerBound,
            upperBound: this.props.location.state.upperBound,
            lowerEfficiency: this.props.location.state.lowerEfficiency,
            upperEfficiency: this.props.location.state.upperEfficiency,
        }
        
    };

    componentDidMount = () =>
    {
        ReactGA.event({category: this.props.location.state.accountName, action: "Budget Plan List", label: this.props.location.state.selectedBudgetOutput})
    }

    switchBudgetOutputResult = (e, recentBudgetInputs) =>
    {
        var selectedBudget = recentBudgetInputs.filter(x => x.id.toString() === e._targetInst.key )
        this.setState({campaignGroupingsAmount: selectedBudget[0].campaignGroupCount, conversionType: selectedBudget[0].conversionType, modelFrequency: selectedBudget[0].modelFrequency, selectedBudgetOutput: e.target.textContent, selectedBudgetOutputId: e._targetInst.key})
    }

    backToBudgetResults = () =>
    {
        this.setState({isRedirect:true})
    }

    render() {
        return(

            this.state.isRedirect ?  
            
            <Redirect to={{
                pathname: '/',
                state:{accountId: this.state.accountId}
            }}></Redirect> :

             parseInt(this.state.campaignGroupingsAmount) < 15 ? 
             <CampaignGroupingTemplate lowerBound={this.state.lowerBound} upperBound={this.state.upperBound} lowerEfficiency={this.state.lowerEfficiency} upperEfficiency={this.state.upperEfficiency} backToBudgetResults={this.backToBudgetResults} switchBudgetOutputResult={this.switchBudgetOutputResult} modelFrequency={this.state.modelFrequency} conversionType={this.state.conversionType} accountId={this.state.accountId} selectedBudgetOutput={this.state.selectedBudgetOutput} selectedBudgetOutputId={this.state.selectedBudgetOutputId}></CampaignGroupingTemplate>
             :
             <CampaignTemplate lowerBound={this.state.lowerBound} upperBound={this.state.upperBound} lowerEfficiency={this.state.lowerEfficiency} upperEfficiency={this.state.upperEfficiency} backToBudgetResults={this.backToBudgetResults} switchBudgetOutputResult={this.switchBudgetOutputResult} modelFrequency={this.state.modelFrequency} conversionType={this.state.conversionType} accountId={this.state.accountId} selectedBudgetOutput={this.state.selectedBudgetOutput} selectedBudgetOutputId={this.state.selectedBudgetOutputId}></CampaignTemplate>
            
             );
    }
}

export default BudgetResultsOutput
